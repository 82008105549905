<template>
  <q-card
    class="pot-card"
  >
    <div
      :class="`bg-${color}`"
      class="flex pot-card-header"
    >
      <m-icons-type
        :size="size"
        :inverted="inverted"
        :rounded="rounded"
        :type="type !== 'other' ? type : 'concierge'"
      />
      <div
        class="flex-grow"
      >
        <q-btn-dropdown
          flat
          align="left"
          :label="title"
        >
          <q-list>
            <q-item v-close-popup clickable @click="$router.push(errorsLink)">
              Alert ({{ errorsCount }})
            </q-item>
            <q-item v-close-popup clickable @click="$router.push(amendedLink)">
              Amended ({{ amendedCount }})
            </q-item>
            <q-item v-close-popup clickable @click="$router.push(bookedTodayLink)">
              Completed ({{ bookedTodayCount }})
            </q-item>
            <q-item v-close-popup clickable @click="$router.push(allLink)">
              View all ({{ totalCount }})
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
      <div
        class="flex-block text-right number"
        v-text="errorsCount"
      />
    </div>
    <div class="pot-card-actions row">
      <div class="q-col-lg-6">
        <q-btn size="md" :disabled="totalCount === 0" flat @click="$router.push(allLink)">
          All requests: {{ totalCount }}
        </q-btn>
      </div>
    </div>
  </q-card>
</template>

<script type="text/javascript">
import travelContents from 'mixins/travelContents'
import { MIconsType } from 'components/'
export default {
  name: 'Dashboard',
  components: { MIconsType },
  mixins: [travelContents],
  props: {
    allLink: {
      type: String,
      default: ''
    },
    errorsLink: {
      type: String,
      default: ''
    },
    amendedLink: {
      type: String,
      default: ''
    },
    bookedTodayLink: {
      type: String,
      default: ''
    },
    label: String,
    totalCount: [String, Number],
    errorsCount: [String, Number],
    amendedCount: [String, Number],
    bookedTodayCount: [String, Number],
    type: String,
    size: {
      type: String,
      default: '24px'
    },
    inverted: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      switch (this.label) {
      case 'other':
        return 'Concierge'
      case 'onwardtravel':
        return 'Onward Travel'
      case 'Demand Responsive Transport':
        return 'DRT'
      default:
        return this.label.charAt(0).toUpperCase() + this.label.slice(1)
      }
    },
    color () {
      switch (this.type) {
      case 'other':
        return this.getTravelContentColor('concierge')
      case 'ridehailing':
        return this.getTravelContentColor('taxi')
      default:
        return this.getTravelContentColor(this.type) || 'grey'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.card-right
  display: flex;
  justify-content: right;
  flex-direction: column;
.number
  font-size 20px
.type
  font-size 16px
  text-transform capitalize
.pot-card {
  color white
  padding 0

  .pot-card-header {
    border-bottom: 1px solid #eee
    > * {
      padding 5px 0 0
    }

    > :first-child {
      margin-left: 5px
    }

    > :last-child {
      padding 5px 10px
    }
  }
}
.narrow {
  padding 4px 8px !important
}
.pot-card .flex-grow
  flex-grow 1
.pot-card-header
  color: white
.pot-card-actions {
  padding 5px
  color: $primary
}
.q-item {
  line-height 32px
}
</style>
